import {
	MYINFO_PROFILE,
	MYINFO_PROFILE_PUT,
	MYINFO_PROFILE_PUTTING,
	MYINFO_AVATAR_POST,
	MYINFO_PROFILE_MERGE,
	MYINFO_PROFILE_ADD,
	CHANGE_LIMIT_USER_CHATTING,
	CHANGE_STATUS
} from '../constants/ActionTypes'

export const PROFILE_SAVE_STATUS = {
	SUCCESS: 0,
	LINK_HAS_NG_WORD: 68,
	TWITTER_LINK_ERROR: 69,
	FACEBOOK_LINK_ERROR: 70,
	OTHER_LINK_ERROR: 71,
	BANNED_WORD_IN_PROFILE: 250,
}

const initialState = {
	loaded: false,
	data: {},
	savedProfile: null,
	savedCode: PROFILE_SAVE_STATUS.SUCCESS,
}

export const MyProfile = (state = initialState, action) => {
	switch (action.type) {
	case MYINFO_PROFILE:
		return { loaded: true, data: action.data }
	case MYINFO_PROFILE_PUTTING:
		return Object.assign({}, state, action.data, { savedProfile: null })
	case MYINFO_PROFILE_PUT:
		return Object.assign({}, state, action.data, { loaded: true })
	case MYINFO_AVATAR_POST:
		return { loaded: false, data: action.data }
	case MYINFO_PROFILE_MERGE: {
		Object.assign(state.data, action.data)
		return Object.assign({}, state, {
			loaded: true,
		})
	}
	case MYINFO_PROFILE_ADD: {
		for (let i of Object.keys(action.data)) {
			state.data[i] =
					parseInt(state.data[i]) + parseInt(action.data[i])
		}
		return Object.assign({}, state, {
			loaded: true,
			data: state.data,
		})
	}
	case CHANGE_LIMIT_USER_CHATTING:{
		return {
			...state,
			loaded: true,
			data: {
				...state.data,
				fortuneTellerStatus: action.data.status,
				maxNumberUserChatting: action.data.maxNumberUserChatting,
				remainChatNumber: action.data.remainChatNumber,
			},
		}
	}
	case CHANGE_STATUS: 
		return {
			...state,
			data: {
				...state.data,
				fortuneTellerStatus: action.data,
			},
		}
	default:
		return state
	}
}
